// @flow

import {Col, Row} from 'antd';
import React, {useLayoutEffect} from 'react';

import AntdContainer from '../components/AntdContainer';
import HtmlHead from '../components/HtmlHead';

const CookieDeclaration = () => {
  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = `https://consent.cookiebot.com/${process.env.GATSBY_COOKIEBOT_CBID}/cd.js`;
    script.async = true;
    document.getElementById('cookieDeclarationContent').appendChild(script);
  }, []);

  return (
    <div className='section light'>
      <HtmlHead title='Cookie Declaration | PostSeal' description='This website uses cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic.' />
      <div className='content-wrapper'>
        <AntdContainer>
          <Row>
            <Col xs={24} md={{span: 16}}>
              <h1 className='ant-typography page-title'>Cookie Declaration</h1>
              <span id='cookieDeclarationContent'></span>
            </Col>
          </Row>
        </AntdContainer>
      </div>
    </div>
  );
};

export default CookieDeclaration;
